import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { Icon } from '@chakra-ui/icons'
import styled from '@emotion/styled'
import { slugify } from '../../utils'
import {
  Flex,
  Box,
  Stack,
  Heading,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/react'
// import {
//   FiUsers,
//   FiCompass,
//   FiClipboard,
//   FiSearch,
//   FiGrid,
//   FiLayers,
//   FiMap,
//   FiFilePlus,
//   FiKey,
//   FiNavigation,
//   FiBookOpen,
//   FiUser,
//   FiPackage,
//   FiInbox,
//   FiAlertOctagon,
//   FiTag,
//   FiBook,
//   FiFileText,
//   FiDisc,
//   FiPrinter,
//   FiHeadphones,
//   FiVideo,
//   FiArchive,
//   FiPhone,
//   FiUserCheck,
//   FiAward,
//   FiUpload,
//   FiTv,
//   FiTrello,
// } from 'react-icons/fi'

import { FiUsers } from '@react-icons/all-files/fi/FiUsers'
import { FiCompass } from '@react-icons/all-files/fi/FiCompass'
import { FiClipboard } from '@react-icons/all-files/fi/FiClipboard'
import { FiSearch } from '@react-icons/all-files/fi/FiSearch'
import { FiGrid } from '@react-icons/all-files/fi/FiGrid'
import { FiLayers } from '@react-icons/all-files/fi/FiLayers'
import { FiMap } from '@react-icons/all-files/fi/FiMap'
import { FiFilePlus } from '@react-icons/all-files/fi/FiFilePlus'

import { FiKey } from '@react-icons/all-files/fi/FiKey'
import { FiNavigation } from '@react-icons/all-files/fi/FiNavigation'
import { FiBookOpen } from '@react-icons/all-files/fi/FiBookOpen'
import { FiUser } from '@react-icons/all-files/fi/FiUser'
import { FiPackage } from '@react-icons/all-files/fi/FiPackage'
import { FiInbox } from '@react-icons/all-files/fi/FiInbox'
import { FiAlertOctagon } from '@react-icons/all-files/fi/FiAlertOctagon'
import { FiTag } from '@react-icons/all-files/fi/FiTag'

import { FiBook } from '@react-icons/all-files/fi/FiBook'
import { FiFileText } from '@react-icons/all-files/fi/FiFileText'
import { FiDisc } from '@react-icons/all-files/fi/FiDisc'
import { FiPrinter } from '@react-icons/all-files/fi/FiPrinter'
import { FiHeadphones } from '@react-icons/all-files/fi/FiHeadphones'
import { FiVideo } from '@react-icons/all-files/fi/FiVideo'
import { FiArchive } from '@react-icons/all-files/fi/FiArchive'
import { FiPhone } from '@react-icons/all-files/fi/FiPhone'

import { FiUserCheck } from '@react-icons/all-files/fi/FiUserCheck'
import { FiAward } from '@react-icons/all-files/fi/FiAward'
import { FiUpload } from '@react-icons/all-files/fi/FiUpload'
import { FiTv } from '@react-icons/all-files/fi/FiTv'


//import { FaIdCard, FaRegAddressCard } from 'react-icons/fa'

import { FaIdCard } from '@react-icons/all-files/fa/FaIdCard'
import { FaRegAddressCard } from '@react-icons/all-files/fa/FaRegAddressCard'

//import { HiOutlineKey, HiOutlineOfficeBuilding } from 'react-icons/hi'

import { HiOutlineKey } from '@react-icons/all-files/hi/HiOutlineKey'
import { HiOutlineOfficeBuilding } from '@react-icons/all-files/hi/HiOutlineOfficeBuilding'

import { NavLink } from './NavLink'
import { CanPerform } from '../Hoc/CanPerform'
import { RBAC_FUNCTIONALITIES, RBAC_PERMISSIONS, RBAC_ROLES } from '../../utils/RBAC/constants'
import { AuthContext } from '../../context/auth/Auth'
import { rbacChecker } from '../../utils/RBAC/rbacconditionals'

const StyledBox = styled(Box)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export function Sidebar({ location, sidebarOpen }) {
  const auth = React.useContext<any>(AuthContext)
  const [activeIndex, setActiveIndex] = useState(null)
  const [sidebarData, setSidebarData] = useState([])

  const isDEV = process.env.REACT_APP_API_URL === 'https://iatse-finance-v2-be.preview.winmill.com/'
  const isUAT = process.env.REACT_APP_API_URL === 'https://iauat-be.preview.winmill.com/'
  const isPROD = process.env.REACT_APP_API_URL === 'https://iatse-finance-be.winmill.net/'

  const prodBaseURL = isPROD ? 'https://admin.iatse-intl.org' : 'https://iauat.preview.winmill.com'

  const data = (auth) => {
    const localID = auth?.user?.locals?.[0]?.local?.localID?.toString()
    return [
      {
        title: 'Admin',
        path: '/admin',
        icon: HiOutlineKey,
        hide: rbacChecker(auth?.user, RBAC_FUNCTIONALITIES.HOME_SHOW_IN_SIDEBAR),
        items: [
          {
            label: 'Roles',
            href: '/admin/roles/search',
            hide: rbacChecker(auth?.user, RBAC_FUNCTIONALITIES.HOME_ROLES_SEARCH),
          },
          {
            label: 'Users',
            href: '/admin/users/search',
            hide: rbacChecker(auth?.user, RBAC_FUNCTIONALITIES.HOME_USERS_SEARCH),
          },
          {
            label: 'Member Address File',
            href: '/admin/member-address-file',
            permission: RBAC_PERMISSIONS.MEMBER_ADDRESS_FILE,
          },
          {
            label: 'Impersonate Admin',
            href: '/admin/impersonate-admin',
            permission: RBAC_PERMISSIONS.IMPERSONATE_ADMIN,
          },
          {
            label: 'Unlock User',
            href: '/admin/users/unlock',
            permission: RBAC_PERMISSIONS.UNLOCK_USER,
          },
          {
            label: 'Revoke User',
            href: '/admin/users/revoke',
            permission: RBAC_PERMISSIONS.REVOKE_USER,
          },
          {
            label: 'Reset User',
            href: '/admin/users/reset',
            permission: RBAC_PERMISSIONS.RESET_USER,
          },
        ],
      },
      {
        title: 'Locals',
        path: '/locals',
        icon: FiCompass,
        permission: [RBAC_PERMISSIONS.LOCAL_INFO, RBAC_PERMISSIONS.LOCALS_VIEW],
        items: [
          {
            label: 'Locals',
            href: '/locals/search',
            permission: [RBAC_PERMISSIONS.LOCALS_VIEW, RBAC_ROLES.SUPER_ADMIN],
          },
          {
            label: 'Local Info',
            href: `/locals/${localID}/view`,
            permission: RBAC_PERMISSIONS.LOCAL_INFO,
            hide:
              auth?.user?.appRoles?.includes(RBAC_ROLES.SUPER_ADMIN) ||
              auth?.user?.appRoles?.includes(RBAC_ROLES.DISTRICT),
          },
          {
            label: 'Local Committee Report',
            href: '/locals/committee-report',
            permission: RBAC_PERMISSIONS.LOCALS_COMMITEE_REPORT,
          },
          {
            label: 'Officers Update Report',
            href: '/locals/officer-update-report',
            permission: RBAC_PERMISSIONS.OFFICERS_UPDATE_REPORT,
          },
          {
            label: 'Supplies Sent Maintenance',
            href: '/locals/supplies-sent-maintenance',
            permission: RBAC_PERMISSIONS.LOCALS_SUPPLIES_SENT_MAINTENANCE,
          },
          {
            label: 'Merge Locals',
            href: '/locals/merge-locals',
            permission: RBAC_PERMISSIONS.MERGE_LOCALS,
          },
        ],
      },
      {
        title: 'Committees',
        href: '/committees/view?region=US',
        icon: FiAward,
        permission: RBAC_PERMISSIONS.CANADIAN_COMMITTEES,
      },
      {
        title: 'Member Card Maint.',
        href: '/member-card-maintenance/search',
        icon: FaRegAddressCard,
        permission: RBAC_PERMISSIONS.MEMBER_CARD_MAINT,
      },
      {
        title: 'Members',
        path: '/members',
        icon: FiUsers,
        permission: RBAC_PERMISSIONS.MEMBERS,
        items: [
          { label: 'Search Members', href: '/members/search', permission: RBAC_PERMISSIONS.MEMBERS_VIEW },
          {
            label: 'Membership Applications',
            href: '/members/membership-applications',
            permission: RBAC_PERMISSIONS.MEMBERSHIP_APPLICATION,
          },
          {
            label: 'Membership Requests',
            href: '/members/membership-requests',
            permission: RBAC_PERMISSIONS.MEMBERSHIP_REQUEST,
          },
          {
            label: 'Member Group Maintenance',
            href: '/members/member-group-maintenance',
            permission: RBAC_PERMISSIONS.MEMBER_GROUP_MAINTENANCE,
          },
          {
            label: 'Multiple Member Search',
            href: '/members/multiple-member-search',
            permission: RBAC_PERMISSIONS.MULTIPLE_MEMBER_SEARCH,
          },
        ],
      },
      {
        title: 'Quarterly Reports',
        href: '/quarterly-reports',
        icon: FiGrid,
        permission: RBAC_PERMISSIONS.QUARTERLY_REPORTS,
      },
      {
        title: 'Orders',
        path: '/orders',
        icon: FiClipboard,
        permission: RBAC_PERMISSIONS.ORDERS,
        items: [
          { label: 'Search Orders', href: '/orders/search', permission: RBAC_PERMISSIONS.ORDERS },
          { label: 'Items', href: '/orders/items/search', permission: RBAC_PERMISSIONS.ORDERS_VIEW_ITEM },
          { label: 'Stamps', href: '/orders/stamps', permission: RBAC_PERMISSIONS.ORDERS_VIEW_STAMP_ITEM },
          { label: 'Transaction Check', href: '/orders/transaction', permission: RBAC_PERMISSIONS.TRANSACTION_CHECK },
        ],
      },
      {
        title: 'Shows',
        path: '/shows',
        icon: FiTv,
        permission: RBAC_PERMISSIONS.SHOWS,
        items: [
          { label: 'Shows', href: '/shows/search', permission: RBAC_PERMISSIONS.SHOWS_VIEW },
          {
            label: 'Pink Contract Positions',
            href: '/shows/pink-contract-positions/search',
            permission: RBAC_PERMISSIONS.PINK_CONTRACT_POSITIONS,
          },
        ],
      },
      {
        title: 'Companies',
        href: '/companies/search',
        icon: HiOutlineOfficeBuilding,
        permission: RBAC_PERMISSIONS.COMPANIES,
      },
      {
        title: 'Agreements',
        href: '/agreements',
        icon: FiBookOpen,
        permission: RBAC_PERMISSIONS.AGREEMENTS,
      },
      {
        title: 'Grievances',
        href: '/grievances',
        icon: FiInbox,
        permission: RBAC_PERMISSIONS.GRIEVANCES,
      },
      {
        title: 'Contacts',
        href: '/contacts',
        icon: FiUser,
        permission: RBAC_PERMISSIONS.CONTACTS,
      },
      {
        title: 'Locations',
        href: '/locations/search',
        icon: FiNavigation,
        permission: RBAC_PERMISSIONS.LOCATIONS,
      },
      {
        title: 'Agreement Types',
        href: '/agreement-types/search',
        icon: FiBook,
        permission: RBAC_PERMISSIONS.AGREEMENT_TYPES,
      },
      {
        title: 'Contract Provisions',
        href: '/contract-provisions',
        icon: FiPackage,
        permission: RBAC_PERMISSIONS.CONTRACT_PROVISIONS,
      },
      {
        title: 'Contract Reports',
        path: '/contract-reports',
        icon: FiSearch,
        permission: RBAC_PERMISSIONS.CONTRACTS_REPORTS,
        items: [
          {
            label: 'Agreements Ad Hoc Report',
            href: '/contract-reports/agreements-ad-hoc',
            permission: RBAC_PERMISSIONS.AGREEMENTS_AD_HOC_REPORT,
          },
          {
            label: 'Companies Ad Hoc Report',
            href: '/contract-reports/companies-ad-hoc',
            permission: RBAC_PERMISSIONS.COMPANIES_AD_HOC_REPORT,
          },
          {
            label: 'Contacts Ad Hoc Report',
            href: '/contract-reports/contacts-ad-hoc',
            permission: RBAC_PERMISSIONS.CONTACTS_AD_HOC_REPORT,
          },
          {
            label: 'Grievances Ad Hoc Report',
            href: '/contract-reports/grievances-ad-hoc',
            permission: RBAC_PERMISSIONS.GRIEVANCES_AD_HOC_REPORT,
          },
          {
            label: 'Agreements Mailing',
            href: '/contract-reports/agreements-mailing',
            permission: RBAC_PERMISSIONS.AGREEMENTS_MAILING,
          },
          {
            label: 'Expiring Contracts Ad Hoc Report',
            href: '/contract-reports/expiring-contracts-ad-hoc',
            permission: RBAC_PERMISSIONS.EXPIRING_CONTRACTS_AD_HOC_REPORT,
          },
          {
            label: 'Active Productions List',
            href: '/contract-reports/active-productions-list',
            permission: RBAC_PERMISSIONS.ACTIVE_PRODUCTIONS_LIST,
          },
          {
            label: 'Weekly Mailing',
            href: '/contract-reports/weekly-mailing',
            permission: RBAC_PERMISSIONS.WEEKLY_MAILING,
          },
        ],
      },
      {
        title: 'Forms And Booklets',
        href: '/forms-and-booklets/search',
        icon: FiClipboard,
        permission: RBAC_PERMISSIONS.FORMS_AND_BOOKLETS,
      },
      {
        title: 'Address Upload',
        href: `/local-admin-address-upload`,
        icon: FiUpload,
        permission: RBAC_PERMISSIONS.ADDRESS_UPLOAD,
      },
      {
        title: 'General Managers',
        href: '/general-managers',
        icon: FiKey,
        permission: RBAC_PERMISSIONS.GENERAL_MANAGERS,
      },
      {
        title: 'Reports',
        path: '/reports',
        icon: FiSearch,
        permission: RBAC_PERMISSIONS.REPORTS,
        items: [
          {
            label: '1.0 Reports',
            href: `${prodBaseURL}/Reports.aspx`,
            permission: RBAC_PERMISSIONS.LOCAL_MEMBERSHIP_ROSTER,
            // hide: isDEV || isUAT
          },
          {
            label: 'Report Landing Page',
            href: '/reports/landing-page',
            permission: RBAC_PERMISSIONS.REPORTS,
            hide: isPROD || isUAT
          },
          {
            label: 'Local Membership Roster',
            href: '/reports/local-membership-roster',
            permission: RBAC_PERMISSIONS.LOCAL_MEMBERSHIP_ROSTER,
          },
          {
            label: 'Stamp Purchase & QR Requirement',
            href: '/reports/report-stamp-purchase-qr-requirement',
            permission: RBAC_PERMISSIONS.STAMP_PURCHASE_QR_REQUIREMENTS,
          },
          {
            label: 'TTF Titles Ad Hoc Report',
            href: '/reports/ttf-titles-adHoc',
            permission: RBAC_PERMISSIONS.TTF_TITLES_AD_HOC_REPORT,
          },
          {
            label: 'TTF Member Status Report',
            href: '/reports/ttf-member-status',
            permission: RBAC_PERMISSIONS.TTF_MEMBER_STATUS_REPORT,
          },
          {
            label: 'IL Number Search Report',
            href: isDEV || isUAT ? '/reports/il-number-search' : `${prodBaseURL}/ILNumberReport.aspx`,
            permission: RBAC_PERMISSIONS.IL_NUMBER_SEARCH_REPORT,
          },
        ],
      },
      {
        title: 'Others',
        href: '/others',
        icon: FiLayers,
        permission: RBAC_PERMISSIONS.OTHER,
        items: [
          { label: 'Comps members', href: '/others/comps/search', permission: RBAC_PERMISSIONS.COMPS_MEMBERS },
          { label: 'USPS', href: '/others/usps/upload', permission: RBAC_PERMISSIONS.UPLOAD_USPS_FILE },
        ],
      },
      {
        title: 'Traveling Mem. Rpts.',
        path: '/traveling-members-reports',
        icon: FiPrinter,
        permission: RBAC_PERMISSIONS.TRAVELING_MEM_RPTS,
        items: [
          { label: 'Traveling Members by Local', permission: RBAC_PERMISSIONS.TRAVELING_MEMBERS_BY_LOCAL,
            href: isDEV || isUAT ? '/traveling-members-reports/traveling-members-by-local' : `${prodBaseURL}/MembersOnTheRoad.aspx`
          },
          { label: 'Traveling Members Contract History', permission: RBAC_PERMISSIONS.TRAVELING_MEMBERS_CONTRACT_HISTORY,
            href: isDEV || isUAT ? '/traveling-members-reports/traveling-members-contract-history' : `${prodBaseURL}/ReportRoadmanPinkContractHistory.aspx`
          },
          { label: 'Traveling Members by Craft', permission: RBAC_PERMISSIONS.TRAVELING_MEMBERS_BY_CRAFT,
            href: isDEV || isUAT ? '/traveling-members-reports/traveling-members-by-craft' : `${prodBaseURL}/MembersOnTheRoadByCraft.aspx`
          },
          { label: 'Traveling Members by Department', permission: RBAC_PERMISSIONS.TRAVELING_MEMBERS_BY_DEPARTMENT,
            href: isDEV || isUAT ? '/traveling-members-reports/traveling-members-by-department' : `${prodBaseURL}/MembersOnTheRoadByCategory.aspx`
          },
          { label: 'Show List by Status', permission: RBAC_PERMISSIONS.SHOW_LIST_BY_STATUS,
            href: isDEV || isUAT ? '/traveling-members-reports/show-list-by-status' : `${prodBaseURL}/ShowsByStatusReport.aspx`
          },
          { label: 'Type of Show', permission: RBAC_PERMISSIONS.TYPE_OF_SHOW,
            href: isDEV || isUAT ? '/traveling-members-reports/type-of-show' : `${prodBaseURL}/ReportTypeofShow.aspx`
          },
          { label: 'Type of Salary', permission: RBAC_PERMISSIONS.TYPE_OF_SALARY,
            href: isDEV || isUAT ? '/traveling-members-reports/type-of-salary' : `${prodBaseURL}/ReportTypeOfSalary.aspx`
          },
          { label: 'Local Report', permission: RBAC_PERMISSIONS.LOCAL_REPORT,
            href: isDEV || isUAT ? '/traveling-members-reports/local-report' : `${prodBaseURL}/ReportLocalReport.aspx`
          },
          { label: 'Pink Contract Report', permission: RBAC_PERMISSIONS.PINK_CONTRACT_REPORT,
            href: isDEV || isUAT ? '/traveling-members-reports/pink-contract-report' : `${prodBaseURL}/RoadmanPinkContractReport.aspx`
          },
        ]
      },
      {
        title: 'Template Management',
        href: '/template-management',
        icon: FiFilePlus,
        permission: RBAC_PERMISSIONS.TEMPLATE_MGMT,
      },
      {
        title: 'Convention',
        href: '/convention',
        icon: FiHeadphones,
        permission: RBAC_PERMISSIONS.CONVENTION,
        items: [
          {
            label: 'Convention',
            href: isDEV || isUAT ? '/convention/search' : `${prodBaseURL}/Delegates.aspx`,
            permission: RBAC_PERMISSIONS.CONVENTION,
          },
          {
            label: 'Delegate & Vote Stats',
            href: isDEV || isUAT ? '/convention/delegate-and-vote-stats' : `${prodBaseURL}/DelegateStatistics.aspx`,
            permission: RBAC_PERMISSIONS['DELEGATE_&_VOTE_STATS'],
          },
          {
            label: 'Delegates By Local',
            href: isDEV || isUAT ? '/convention/delegates-by-local' : `${prodBaseURL}/DelegatesByLocal.aspx`,
            permission: RBAC_PERMISSIONS.DELEGATES_BY_LOCAL,
          },
          {
            label: 'Delegates By District',
            href: isDEV || isUAT ? '/convention/delegates-by-district' : `${prodBaseURL}/DelegatesByDistrict.aspx`,
            permission: RBAC_PERMISSIONS.DELEGATES_BY_DISTRICT,
          },
          {
            label: 'Charges to Master Account',
            href:
              isDEV || isUAT
                ? '/convention/charges-to-master-account'
                : `${prodBaseURL}/DelegateMasterAccountReport.aspx`,
            permission: RBAC_PERMISSIONS.CHARGES_TO_MASTER_ACCOUNT,
          },
          {
            label: 'Travel Reimbursements',
            href:
              isDEV || isUAT ? '/convention/travel-reimbursements' : `${prodBaseURL}/DelegateTravelReimbursements.aspx`,
            permission: RBAC_PERMISSIONS.TRAVEL_REIMBURSEMENTS,
          },
          {
            label: 'Travel Expense Summary',
            href: isDEV || isUAT ? '/convention/travel-expense-summary' : `${prodBaseURL}/TravelExpenseSummary.aspx`,
            permission: RBAC_PERMISSIONS.TRAVEL_EXPENSE_SUMMARY,
          },
          {
            label: 'Print Delegate Badges',
            href: isDEV || isUAT ? '/convention/print-delegate-badges' : `${prodBaseURL}/DelegateCards.aspx`,
            permission: RBAC_PERMISSIONS.PRINT_DELEGATE_BADGES,
          },
          {
            label: 'Delegate Checks',
            href: isDEV || isUAT ? '/convention/delegate-checks' : `${prodBaseURL}/DelegateChecks.aspx`,
            permission: RBAC_PERMISSIONS.DELEGATE_CHECKS,
          },
          {
            label: 'Delegates w/no Travel Expenses',
            href:
              isDEV || isUAT
                ? '/convention/delegate-wno-travel-expenses'
                : `${prodBaseURL}/DelegatesWithNoTravelExpensesReport.aspx`,
            permission: RBAC_PERMISSIONS['DELEGATES_W/_NO_TRAVEL_EXPENSES'],
          },
          {
            label: 'Set Convention Per Diem',
            href: isDEV || isUAT ? '/convention/set-convention-per-diem' : `${prodBaseURL}/ConventionPerDiem.aspx`,
            permission: RBAC_PERMISSIONS.SET_CONVENTION_PER_DIEM,
          },
          {
            label: 'Proofread Short Jurisdictions',
            href:
              isDEV || isUAT
                ? '/convention/proofread-short-jurisdiction'
                : `${prodBaseURL}/ProofShortJurisdictions.aspx`,
            permission: RBAC_PERMISSIONS.PROOFREAD_SHORT_JURISDICTIONS,
          },
          {
            label: 'Proofread Max Delegates and Votes',
            href:
              isDEV || isUAT
                ? '/convention/proofread-max-delegates-and-votes'
                : `${prodBaseURL}/ProofDelegateVotes.aspx`,
            permission: RBAC_PERMISSIONS['PROOFREAD_MAX_DELEGATES_&_VOTES'],
          },
          {
            label: 'Committee Maintenance',
            href: isDEV || isUAT ? '/convention/committee-maintenance' : `${prodBaseURL}/CommitteeMaintenance.aspx`,
            permission: RBAC_PERMISSIONS.COMMITTEE_MAINTENANCE,
          },
          {
            label: 'Review Delegate Requests',
            href:
              isDEV || isUAT
                ? '/convention/review-delegate-requests'
                : `${prodBaseURL}/ConventionTravelRequestReview.aspx`,
            permission: RBAC_PERMISSIONS.REVIEW_DELEGATE_REQUESTS,
          },
        ],
      },
      {
        title: 'Press Agents',
        href: isPROD
          ? 'https://admin.iatse-intl.org/PressAgentContracts.aspx'
          : 'https://iauat.preview.winmill.com/PressAgentContracts.aspx',
        icon: FiVideo,
        permission: RBAC_PERMISSIONS.PRESS_AGENTS,
      },
      {
        title: 'Search Contracts',
        href: '/search-contracts',
        icon: FiSearch,
        permission: RBAC_PERMISSIONS.CONTRACTS,
      },
      {
        title: 'Venues',
        href: '/venues',
        icon: FiMap,
        permission: RBAC_PERMISSIONS.VENUES,
      },
      {
        title: 'TTF Titles',
        href: '/ttf-titles',
        icon: FiTag,
        items: [
          {
            label: 'TTF Titles',
            href: '/ttf-titles/search',
            permission: [RBAC_PERMISSIONS.VIEW_TTF_TITLE, RBAC_PERMISSIONS.ADD_TTF_TITLE],
          },
          {
            label: 'Upload TTF File',
            href: '/ttf-titles/upload',
            permission: RBAC_PERMISSIONS.UPLOAD_TTF_FILES,
          },
          {
            label: 'TTF Lists',
            href: '/ttf-titles/lists',
            permission: [
              RBAC_PERMISSIONS.VIEW_TTF_TITLE,
              RBAC_PERMISSIONS.ADD_TTF_TITLE,
              RBAC_PERMISSIONS.EDIT_TTF_TITLE,
            ],
          },
        ],
        permission: RBAC_PERMISSIONS.TTF_TITLES,
      },
      {
        title: 'Organizing',
        href: isDEV || isUAT ? '/organizing' : `${prodBaseURL}/OrgSearch.aspx`,
        icon: FiArchive,
        permission: RBAC_PERMISSIONS.ORGANIZING,
      },
      {
        title: 'TTF Contacts',
        href: '/ttf-contacts',
        icon: FiPhone,
        items: [
          {
            label: 'TTF Contacts Search',
            href: '/ttf-contacts/search',
            permission: RBAC_PERMISSIONS.VIEW_TTF_CONTACT,
          },
          {
            label: 'Primary Job Classifications',
            href: '/ttf-contacts/primary-job-classifications',
            permission: RBAC_PERMISSIONS.PRIMARY_JOB_CLASSIFICATIONS,
          },
          {
            label: 'Migrate TTF Contacts',
            href: '/ttf-contacts/migrate',
            permission: RBAC_PERMISSIONS.MIGRATE_TTF_CONTACTS,
          },
        ],
        permission: RBAC_PERMISSIONS.TTF_CONTACTS,
      },
      {
        title: 'Event Contacts',
        href: '/event-contacts',
        icon: FiUserCheck,
        permission: RBAC_PERMISSIONS.EVENT_CONTACTS,
      },
      {
        title: 'Member Events',
        href: '/member-events/search',
        icon: FiDisc,
        permission: RBAC_PERMISSIONS.MEMBERS_EVENTS,
      },
      {
        title: 'Safety App',
        path: '/safety-app',
        icon: FiAlertOctagon,
        permission: RBAC_PERMISSIONS.SAFETY_APP,
        items: [
          {
            label: 'Main Menu Items',
            href: '/safety-app/main-menu-items',
            permission: RBAC_PERMISSIONS.MAIN_MENU_ITEMS,
          },
          { label: 'Sub Menu Items', href: '/safety-app/sub-menu-items', permission: RBAC_PERMISSIONS.SUB_MENU_ITEMS },
          { label: 'Content', href: '/safety-app/content', permission: RBAC_PERMISSIONS.CONTENT },
          { label: 'Safety Numbers', href: '/safety-app/safety-numbers', permission: RBAC_PERMISSIONS.SAFETY_NUMBERS },
          { label: 'Emails', href: '/safety-app/emails', permission: RBAC_PERMISSIONS.EMAILS },
          {
            label: 'Submitted Hazard Forms',
            href: '/safety-app/submitted-hazard-forms',
            permission: RBAC_PERMISSIONS.SUBMITTED_HAZARD_FORMS,
          },
        ],
      },
      {
        title: 'Audit Log',
        href: '/audit-log',
        icon: FiFileText,
        permission: RBAC_PERMISSIONS.AUDIT_LOG,
      },
      {
        title: 'Visa Tracking',
        path: '/visa-tracking',
        icon: FaIdCard,
        permission: RBAC_PERMISSIONS.VISA_TRACKING,
        items: [
          {
            label: 'Search Visa Tracking',
            href: '/visa-tracking/search',
            permission: RBAC_PERMISSIONS.VISA_TRACKING,
          },
          {
            label: 'Manage Beneficiaries',
            href: '/visa-tracking/beneficiaries/search',
            permission: RBAC_PERMISSIONS.MANAGE_BENEFICIARIES,
          },
          {
            label: 'Manage Filing Agencies',
            href: '/visa-tracking/filing-agencies/search',
            permission: RBAC_PERMISSIONS.MANAGE_FILING_AGENCIES,
          },
          {
            label: 'Manage Petitioners',
            href: '/visa-tracking/petitioners/search',
            permission: RBAC_PERMISSIONS.MANAGE_PETITIONERS,
          },
          {
            label: 'Manage INS Agencies',
            href: '/visa-tracking/ins-agencies/search',
            permission: RBAC_PERMISSIONS.MANAGE_INS_AGENCIES,
          },
          {
            label: 'Manage Engagements',
            href: '/visa-tracking/engagements/search',
            permission: RBAC_PERMISSIONS.MANAGE_ENGAGEMENTS,
          },
          {
            label: 'Visa by Engagement Report',
            href: '/visa-tracking/visa-engagement-report',
            permission: RBAC_PERMISSIONS.VISA_BY_ENGAGEMENT_REPORT,
          },
        ],
      },
    ]
  }

  useEffect(() => {
    const sidebarData = data(auth)
    const currentPath = location.pathname || ''
    const paths = sidebarData.filter((opt) => !opt.hide).map((menuItem) => menuItem.path)
    const index = paths.findIndex((path) => currentPath.startsWith(path))
    setSidebarData(sidebarData)
    setActiveIndex(index < 0 ? null : index)
  }, [auth])

  return (
    <Flex
      bgColor="#CBD5E0"
      zIndex={['10', '10', 'unset']}
      style={{
        transition: 'min-width 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
      }}
      w={[sidebarOpen ? '16rem' : '0', sidebarOpen ? '20rem' : '0', sidebarOpen ? '20rem' : '0']}
      as="section"
      maxH="100vh"
      height="100vh"
      bg={['white', 'white', 'bg-canvas']}
      overflowY="scroll"
      position={['absolute', 'absolute', 'sticky']}
      top="0"
      overflowX="scroll"
      css={{
        '&::-webkit-scrollbar': {
          width: '4px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '24px',
        },
      }}
    >
      <Flex
        flex="1"
        bg="bg-accent"
        color="on-accent"
        w={{ base: 'full', sm: 'xs' }}
        py={{ base: '0', sm: '0' }}
        px={{ base: '4', sm: '6' }}
        position="absolute"
        h="100%"
      >
        <Stack justify="space-between" spacing="1">
          <Stack spacing={{ base: '5', sm: '6' }}>
            <Flex alignItems="center" w="full" h="88px" position="sticky" top="0" bgColor="white" zIndex={1}>
              <Flex
                w={['3rem', '3rem', '3.5rem']}
                p="1rem"
                position="relative"
                justifyContent="center"
                overflow="visible"
              >
                <StyledBox w={['3rem', '3rem', '4rem']} position="absolute">
                  <img src="/v2/images/IATSE_logo_4c.svg" />
                </StyledBox>
              </Flex>
              <Heading
                fontSize={['0.8rem', '0.8rem', '1.05rem']}
                letterSpacing="tight"
                color="blackAlpha.800"
                flex={1}
                as="h1"
                ml={['2', '2', '3']}
              >
                IATSE Member & Traveling Members Application
              </Heading>
            </Flex>

            <Accordion
              style={{ marginTop: 0 }}
              index={activeIndex}
              onChange={setActiveIndex}
              allowMultiple
              as="ul"
              listStyleType="none"
            >
              {sidebarData
                .filter((opt) => !opt.hide)
                .map((group, i) => {
                  const isChildSelected = group.items?.map((i) => i.href).includes(location.pathname)
                  const isParentSelected = group.href === location.pathname
                  return group.items ? (
                    <CanPerform permission={group.permission} key={group.title}>
                      <AccordionItem border={0} as="li" id={`sidebar-${i}-${slugify(group.title)}`}>
                        <AccordionButton p="2" borderRadius="md" _hover={{ bg: 'gray.50' }}>
                          <Icon
                            as={group.icon}
                            fontSize="2xl"
                            mr="3"
                            color={isChildSelected ? 'blackAlpha.900' : 'blackAlpha.700'}
                          />
                          <Box
                            flex="1"
                            textAlign="start"
                            fontSize="sm"
                            fontWeight="700"
                            color={isChildSelected ? 'blackAlpha.900' : 'blackAlpha.700'}
                          >
                            {group.title}
                          </Box>
                          <AccordionIcon fontSize="2xl" color="blackAlpha.700" />
                        </AccordionButton>
                        <AccordionPanel pb="3" px="0" pt="0">
                          {group.items
                            ?.filter((d) => !d.hide)
                            ?.map((item, index) => (
                              <CanPerform permission={item.permission} key={index}>
                                <NavLink
                                  href={item.href}
                                  icon={item.icon}
                                  isHighlighted={item.href === location.pathname}
                                  id={`sidebar-${i}-sub-${slugify(item.label)}`}
                                >
                                  {item.label}
                                </NavLink>
                              </CanPerform>
                            ))}
                        </AccordionPanel>
                      </AccordionItem>
                    </CanPerform>
                  ) : (
                    <CanPerform permission={group.permission} key={group.title}>
                      <Link to={group.href}>
                        <AccordionItem border={0} as="li" id={`sidebar-${i}-${slugify(group.title)}`}>
                          <AccordionButton
                            p="2"
                            borderRadius="md"
                            bg={isParentSelected ? 'gray.100' : 'transparent'}
                            _hover={{ bg: 'gray.50' }}
                          >
                            <Icon
                              color={isParentSelected ? 'blackAlpha.900' : 'blackAlpha.700'}
                              as={group.icon}
                              fontSize="2xl"
                              mr="3"
                            />
                            <Box
                              color={isParentSelected ? 'blackAlpha.900' : 'blackAlpha.700'}
                              flex="1"
                              textAlign="start"
                              fontSize="sm"
                              fontWeight="700"
                            >
                              {group.title}
                            </Box>
                          </AccordionButton>
                        </AccordionItem>
                      </Link>
                    </CanPerform>
                  )
                })}
            </Accordion>
          </Stack>
        </Stack>
      </Flex>
    </Flex>
  )
}
